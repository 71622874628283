import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';

import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditInvoiceContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-invoice-container/edit-invoice-container.component';
import { EditInvoiceSessionContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-invoice-session-container/edit-invoice-session-container.component';
import { EditInvoiceSessionContainerConfig } from '@app/_dialogs/row-edit-dialog/containers/edit-invoice-session-container/editInvoiceSessionContainerConfig';
import { EditInvoiceSessionContainerResultData } from '@app/_dialogs/row-edit-dialog/containers/edit-invoice-session-container/editInvoiceSessionContainerResultData';
import { EditInvoiceSessionServiceProviderContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-invoice-session-service-provider-container/edit-invoice-session-service-provider-container.component';
import { EditInvoiceSessionServiceProviderContainerConfig } from '@app/_dialogs/row-edit-dialog/containers/edit-invoice-session-service-provider-container/editInvoiceSessionServiceProviderContainerConfig';
import { RowEditDialogComponent } from '@app/_dialogs/row-edit-dialog/row-edit-dialog.component';
import { TextDialogComponent } from '@app/_dialogs/textdialog/textdialog.component';
import { setMinTime } from '@app/_helpers/functions/date-functions';
import { Delegator } from '@app/_models/delegationDto';
import { CatalogItemType } from '@app/_models/enums/catalogItemType';
import { InvoiceStatus } from '@app/_models/enums/invoiceStatus';
import { InstitutionWithConfigurationDto } from '@app/_models/institutionDto';
import { InvoiceDto } from '@app/_models/invoiceDto';
import { InvoiceSessionDto } from '@app/_models/invoiceSessionDto';
import { InvoiceSessionPositionDto } from '@app/_models/invoiceSessionPositionDto';
import { InvoiceSessionServiceProviderDto } from '@app/_models/invoiceSessionServiceProviderDto';
import { PatientDto } from '@app/_models/patientDto';
import { DelegationService } from '@app/_services/delegation.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { InvoiceService } from '@app/_services/invoice.service';
import { InvoiceSessionService } from '@app/_services/invoiceSession.service';
import { InvoiceSessionServiceProviderService } from '@app/_services/invoiceSessionServiceProvider.service';
import { PatientService } from '@app/_services/patient.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { BsModalService } from 'ngx-bootstrap/modal';
import { first, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-invoice',
  templateUrl: './user-invoice.component.html',
  styleUrls: ['./user-invoice.component.scss'],
})
export class UserInvoiceComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  invoice!: InvoiceDto;
  patient?: PatientDto;

  invoiceStatus: typeof InvoiceStatus = InvoiceStatus;
  catalogItemType: typeof CatalogItemType = CatalogItemType;
  invoiceDataSource!: BaseTableDataServiceDataSource<InvoiceDto, number>;
  institution$: ReplaySubject<InstitutionWithConfigurationDto> = new ReplaySubject(1);

  delegator: Delegator | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private delegationService: DelegationService,
    private invoiceSessionServiceProviderService: InvoiceSessionServiceProviderService,
    private invoiceSessionService: InvoiceSessionService,
    private patientService: PatientService,
    private institutionService: InstitutionService,
    public modalService: BsModalService,
    private errorHandler: ErrorHandlerService,
    public translate: TranslateService,
    public oauthService: OAuthService,
  ) {}

  ngOnInit(): void {
    this.invoiceDataSource = new BaseTableDataServiceDataSource<InvoiceDto, number>(
      this.invoiceService,
      this.errorHandler,
    );

    // Handle update to route parameters
    this.route.params.subscribe(() => {
      this.loadData();
    });
  }

  loadData() {
    this.loadingSubject.next(true);

    const delegatorUserId = this.route.snapshot.queryParams.delegatorUserId;
    const invoiceId = this.route.snapshot.params.id;

    // set delegator first if present as parameter
    const delegators = delegatorUserId ? this.delegationService.delegators$ : of([]);
    delegators
      .pipe(
        take(1),
        tap((delegators) => {
          if (delegators.length === 0) {
            return;
          }

          const delegator = delegators.find((d) => d.delegatorUserId === delegatorUserId);

          if (delegator) {
            this.delegationService.setDelegator(delegator);
          }
        }),
      )
      .subscribe(() => {
        this.loadInvoice(invoiceId);
        this.delegationService.delegator$.subscribe((delegator) => {
          this.delegator = delegator;
        });
      });
  }

  private loadInvoice(invoiceId: number) {
    this.invoiceService.get(invoiceId).subscribe(
      (result) => {
        this.invoice = result;
        this.loadInstitutionDetails(this.invoice.institutionId!);
        this.loadPatient(this.invoice.patientId);
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
        this.loadingSubject.next(false);
      },
    );
  }

  loadPatient(patientId: number) {
    this.loadingSubject.next(true);
    this.patientService.get(patientId).subscribe(
      (response) => {
        this.patient = response;
        this.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
        this.loadingSubject.next(false);
      },
    );
  }

  private loadInstitutionDetails(institutionId: number) {
    this.institutionService
      .getUserChildInstitutions()
      .pipe(
        first(),
        map((institutions) => institutions.find((institution) => institution.id == institutionId)),
      )
      .subscribe(this.institution$);
  }

  calculateSubtotalWithoutSurcharges(
    serviceProvider: InvoiceSessionServiceProviderDto,
    position: InvoiceSessionPositionDto,
  ): number {
    let subtotalWithoutSurcharges = position.catalogItem.value * position.multiplier;

    if (
      serviceProvider &&
      !serviceProvider.isMainServiceProvider &&
      position.catalogItem.type == CatalogItemType.TariffPosition
    ) {
      subtotalWithoutSurcharges = subtotalWithoutSurcharges * serviceProvider.feeKindMultiplier;
    }

    return subtotalWithoutSurcharges;
  }

  calculateSurcharge(surcharge: InvoiceSessionPositionDto, subtotalWithoutSurcharges: number): number {
    return surcharge.catalogItem.surchargeMultiplier * surcharge.surchargeCount * subtotalWithoutSurcharges;
  }

  calculateSubtotal(
    session: InvoiceSessionDto,
    serviceProvider: InvoiceSessionServiceProviderDto,
    position: InvoiceSessionPositionDto,
  ): number {
    let subtotalWithoutSurcharges = this.calculateSubtotalWithoutSurcharges(serviceProvider, position);

    if (position.isMainItem) {
      let result = subtotalWithoutSurcharges;

      if (session.surcharges && session.surcharges.length) {
        session.surcharges.forEach((element) => {
          result += this.calculateSurcharge(element, subtotalWithoutSurcharges);
        });
      }

      return result;
    } else {
      return subtotalWithoutSurcharges;
    }
  }

  /* columnTypes: 0 = row actions & date, 1 = serviceProvider, 2 = position actions */
  calculateRowSpan(session: InvoiceSessionDto, columnType: number, isVisit: boolean): number {
    var rowSpan = undefined;

    if (isVisit) {
      return 1;
    }

    if (session.serviceProviders.length > 0 && session.tariffPositions.length > 0) {
      rowSpan =
        columnType == 1
          ? session.tariffPositions.length
          : session.serviceProviders.length * session.tariffPositions.length;
    } else if (session.serviceProviders.length > 0) {
      rowSpan = columnType == 1 ? 1 : session.serviceProviders.length;
    } else if (session.tariffPositions.length > 0) {
      rowSpan = session.tariffPositions.length;
    } else if (session.surcharges.length > 0) {
      rowSpan = 1;
    }

    if (rowSpan && columnType == 0 && session.visitPosition) {
      rowSpan = rowSpan + 1;
    }

    return rowSpan ? rowSpan : 1;
  }

  showPosition(position: InvoiceSessionPositionDto, serviceProvider: InvoiceSessionServiceProviderDto): boolean {
    if (
      position.catalogItem.type == CatalogItemType.TariffPosition ||
      position.catalogItem.type == CatalogItemType.Visit
    ) {
      return true;
    }

    if (
      position.catalogItem.type == CatalogItemType.FlatRateForMainServiceProvider &&
      serviceProvider.isMainServiceProvider
    ) {
      return true;
    } else if (position.catalogItem.type == CatalogItemType.FlatRateForAssistant) {
      if (
        serviceProvider.feeKindType == CatalogItemType.FirstAssistant &&
        position.catalogItem.column01.endsWith('3')
      ) {
        return true;
      } else if (
        serviceProvider.feeKindType == CatalogItemType.AdditionalAssistant &&
        position.catalogItem.column01.endsWith('4')
      ) {
        return true;
      }
    }

    return false;
  }

  getPositionsForServiceProvider(
    session: InvoiceSessionDto,
    serviceProvider: InvoiceSessionServiceProviderDto,
  ): InvoiceSessionPositionDto[] {
    var positions = new Array<InvoiceSessionPositionDto>();

    for (var tariffPosition of session.tariffPositions) {
      if (this.showPosition(tariffPosition, serviceProvider)) {
        positions.push(tariffPosition);
      }
    }

    for (var derivedPosition of session.derivedPositions) {
      if (this.showPosition(derivedPosition, serviceProvider)) {
        positions.push(derivedPosition);
      }
    }

    return positions.sort((a, b) => (a.isMainItem > b.isMainItem ? -1 : 1));
  }

  getFactorPosition(
    position?: InvoiceSessionPositionDto,
    serviceProvider?: InvoiceSessionServiceProviderDto,
  ): number | undefined {
    if (!position) {
      return undefined;
    }

    if (
      serviceProvider &&
      !serviceProvider.isMainServiceProvider &&
      position.catalogItem.type == CatalogItemType.TariffPosition
    ) {
      return serviceProvider.feeKindMultiplier;
    }

    return 1;
  }

  addInvoiceSession(): void {
    this.translate.get(['User.Invoice.AddInvoiceSession', 'Global.Save', 'Global.Cancel']).subscribe((res) => {
      this.modalService
        .show<
          RowEditDialogComponent<
            InvoiceSessionDto,
            number,
            EditInvoiceSessionContainerResultData,
            EditInvoiceSessionContainerConfig
          >
        >(RowEditDialogComponent, {
          class: 'modal-lg',
          ignoreBackdropClick: true,
          initialState: {
            containerType: EditInvoiceSessionContainerComponent,
            config: {
              minDate: this.invoice.dateFrom,
              maxDate: setMinTime(this.invoice.dateTo),
              editInvoice: true,
              editSurchargePositions: false,
              editTariffPositions: true,
              catalogViewId: this.invoice.catalogViewId,
              catalogId: this.invoice.catalogId,
              catalogTitle: this.invoice.catalogTitle,
              catalogValidityDate: this.invoice.dateFrom,
            },
            dataSource: new BaseTableDataServiceDataSource<InvoiceSessionDto, number>(
              this.invoiceSessionService,
              this.errorHandler,
            ),
            initialData: <InvoiceSessionDto>{
              invoiceId: this.invoice.id,
              surcharges: new Array<InvoiceSessionPositionDto>(),
              tariffPositions: new Array<InvoiceSessionPositionDto>(),
            },
            dialogConfig: {
              title: res['User.Invoice.AddInvoiceSession'],
              button_confirm_text: res['Global.Save'],
              button_cancel_text: res['Global.Cancel'],
            },
          },
        })
        .content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult.confim) {
            this.loadingSubject.next(true);

            if (onCloseResult.data) {
              this.createInvoiceSession(onCloseResult.data).then((result) => {
                if (result) {
                  onCloseResult.modalRef.hide();
                  this.loadData();
                }
              });
            }
          }
        });
    });
  }

  editInvoiceSurcharges(invoiceSessionDto: InvoiceSessionDto): void {
    this.translate.get(['User.Invoice.EditInvoiceSession', 'Global.Save', 'Global.Cancel']).subscribe((res) => {
      this.modalService
        .show<
          RowEditDialogComponent<
            InvoiceSessionDto,
            number,
            EditInvoiceSessionContainerResultData,
            EditInvoiceSessionContainerConfig
          >
        >(RowEditDialogComponent, {
          class: 'modal-lg',
          ignoreBackdropClick: true,
          initialState: {
            containerType: EditInvoiceSessionContainerComponent,
            config: {
              minDate: this.invoice.dateFrom,
              maxDate: setMinTime(this.invoice.dateTo),
              editInvoice: false,
              editSurchargePositions: true,
              editTariffPositions: false,
              catalogViewId: this.invoice.catalogViewId,
              catalogId: this.invoice.catalogId,
              catalogTitle: this.invoice.catalogTitle,
              catalogValidityDate: this.invoice.dateFrom,
            },
            dataSource: new BaseTableDataServiceDataSource<InvoiceSessionDto, number>(
              this.invoiceSessionService,
              this.errorHandler,
            ),
            id: invoiceSessionDto.id,
            dialogConfig: {
              title: res['User.Invoice.EditInvoiceSession'],
              button_confirm_text: res['Global.Save'],
              button_cancel_text: res['Global.Cancel'],
            },
          },
        })
        .content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult.confim) {
            this.loadingSubject.next(true);

            if (onCloseResult.data) {
              this.updateInvoiceSession(onCloseResult.data, false, true, false).then((result) => {
                if (result) {
                  onCloseResult.modalRef.hide();
                  this.loadData();
                }
              });
            }
          }
        });
    });
  }

  deleteInvoiceSession(invoiceSessionDto: InvoiceSessionDto): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Invoice.DeleteInvoiceSession',
      'User.Invoice.DeleteInvoiceSessionDialogText',
      'Global.Ok',
      'Global.Cancel',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          this.loadingSubject.next(true);

          this.invoiceSessionService.delete(invoiceSessionDto.id).subscribe(
            (result) => {
              this.loadingSubject.next(false);
              this.loadData();
            },
            (errorResponse: HttpErrorResponse) => {
              this.loadingSubject.next(false);
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
        }
      });
    });
  }

  addServiceProvider(invoiceSessionDto: InvoiceSessionDto): void {
    this.translate.get(['User.Invoice.AddAssistant', 'Global.Save', 'Global.Cancel']).subscribe((res) => {
      this.modalService
        .show<
          RowEditDialogComponent<
            InvoiceSessionServiceProviderDto,
            number,
            any,
            EditInvoiceSessionServiceProviderContainerConfig
          >
        >(RowEditDialogComponent, {
          class: 'modal-1000',
          ignoreBackdropClick: true,
          initialState: {
            containerType: EditInvoiceSessionServiceProviderContainerComponent,
            config: {
              catalogId: this.invoice.catalogId,
              invoiceSessionId: invoiceSessionDto.id,
              catalogValidityDate: this.invoice.dateFrom,
            },
            dataSource: new BaseTableDataServiceDataSource<InvoiceSessionServiceProviderDto, number>(
              this.invoiceSessionServiceProviderService,
              this.errorHandler,
            ),
            initialData: <InvoiceSessionServiceProviderDto>{ sessionId: invoiceSessionDto.id },
            dialogConfig: {
              title: res['User.Invoice.AddAssistant'],
              button_confirm_text: res['Global.Save'],
              button_cancel_text: res['Global.Cancel'],
            },
          },
        })
        .content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult.confim) {
            this.loadingSubject.next(true);

            if (onCloseResult.data) {
              this.createServiceProvider(onCloseResult.data).then((result) => {
                if (result) {
                  onCloseResult.modalRef.hide();
                  this.loadData();
                }
              });
            }
          }
        });
    });
  }

  editServiceProvider(invoiceSessionServiceProviderDto: InvoiceSessionServiceProviderDto): void {
    this.translate.get(['User.Invoice.EditAssistant', 'Global.Save', 'Global.Cancel']).subscribe((res) => {
      this.modalService
        .show<
          RowEditDialogComponent<
            InvoiceSessionServiceProviderDto,
            number,
            any,
            EditInvoiceSessionServiceProviderContainerConfig
          >
        >(RowEditDialogComponent, {
          class: 'modal-1000',
          ignoreBackdropClick: true,
          initialState: {
            containerType: EditInvoiceSessionServiceProviderContainerComponent,
            config: {
              catalogId: this.invoice.catalogId,
              invoiceSessionId: invoiceSessionServiceProviderDto.sessionId,
              catalogValidityDate: this.invoice.dateFrom,
            },
            dataSource: new BaseTableDataServiceDataSource<InvoiceSessionServiceProviderDto, number>(
              this.invoiceSessionServiceProviderService,
              this.errorHandler,
            ),
            initialData: cloneDeep(invoiceSessionServiceProviderDto),
            dialogConfig: {
              title: res['User.Invoice.EditAssistant'],
              button_confirm_text: res['Global.Save'],
              button_cancel_text: res['Global.Cancel'],
            },
          },
        })
        .content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult.confim) {
            this.loadingSubject.next(true);

            if (onCloseResult.data) {
              this.updateServiceProvider(onCloseResult.data).then((result) => {
                if (result) {
                  onCloseResult.modalRef.hide();
                  this.loadData();
                }
              });
            }
          }
        });
    });
  }

  deleteServiceProvider(invoiceSessionServiceProviderDto: InvoiceSessionServiceProviderDto): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Invoice.DeleteServiceProvider',
      'User.Invoice.DeleteServiceProviderDialogText',
      'Global.Ok',
      'Global.Cancel',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          this.loadingSubject.next(true);

          this.invoiceSessionServiceProviderService.delete(invoiceSessionServiceProviderDto.id).subscribe(
            (result) => {
              this.loadingSubject.next(false);
              this.loadData();
            },
            (errorResponse: HttpErrorResponse) => {
              this.loadingSubject.next(false);
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
        }
      });
    });
  }

  editTariffPositions(invoiceSessionDto: InvoiceSessionDto): void {
    this.translate.get(['User.Invoice.EditPositions', 'Global.Save', 'Global.Cancel']).subscribe((res) => {
      this.modalService
        .show<
          RowEditDialogComponent<
            InvoiceSessionDto,
            number,
            EditInvoiceSessionContainerResultData,
            EditInvoiceSessionContainerConfig
          >
        >(RowEditDialogComponent, {
          class: 'modal-lg',
          ignoreBackdropClick: true,
          initialState: {
            containerType: EditInvoiceSessionContainerComponent,
            config: {
              minDate: this.invoice.dateFrom,
              maxDate: setMinTime(this.invoice.dateTo),
              editInvoice: false,
              editSurchargePositions: false,
              editTariffPositions: true,
              catalogViewId: this.invoice.catalogViewId,
              catalogId: this.invoice.catalogId,
              catalogTitle: this.invoice.catalogTitle,
              catalogValidityDate: this.invoice.dateFrom,
            },
            dataSource: new BaseTableDataServiceDataSource<InvoiceSessionDto, number>(
              this.invoiceSessionService,
              this.errorHandler,
            ),
            additionalParams: undefined,
            id: invoiceSessionDto.id,
            dialogConfig: {
              title: res['User.Invoice.EditPositions'],
              button_confirm_text: res['Global.Save'],
              button_cancel_text: res['Global.Cancel'],
            },
          },
        })
        .content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult.confim) {
            this.loadingSubject.next(true);

            if (onCloseResult.data) {
              this.updateInvoiceSession(onCloseResult.data, true, false, false).then((result) => {
                if (result) {
                  onCloseResult.modalRef.hide();
                  this.loadData();
                }
              });
            }
          }
        });
    });
  }

  createInvoiceSession(session: InvoiceSessionDto, ignoreWarnings: boolean = false): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Positions are automatically added in the backend when creating a session.
      var additionalParams = new HttpParams().set('ignoreWarnings', ignoreWarnings);

      this.invoiceSessionService.create(session, additionalParams).subscribe(
        (result) => {
          this.loadingSubject.next(false);

          if (result.warnings.length > 0) {
            this.errorHandler.displayWarningDialog(result, undefined, 'Global.SaveAnyway').then((modalRef) => {
              modalRef.content?.onClose.subscribe((onCloseResult) => {
                if (onCloseResult) {
                  resolve(this.createInvoiceSession(session, true));
                } else {
                  resolve(false);
                }
              });
            });
          } else {
            resolve(true);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    });
  }

  updateInvoiceSession(
    session: InvoiceSessionDto,
    updateTariffs: boolean,
    updateSurcharges: boolean,
    ignoreWarnings: boolean = false,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Positions are automatically added in the backend when creating a session.
      var additionalParams = new HttpParams()
        .set('updateTariffs', updateTariffs)
        .set('updateSurcharges', updateSurcharges)
        .set('ignoreWarnings', ignoreWarnings);

      this.invoiceSessionService.update(session, additionalParams).subscribe(
        (result) => {
          this.loadingSubject.next(false);

          if (result.warnings.length > 0) {
            this.errorHandler.displayWarningDialog(result, undefined, 'Global.SaveAnyway').then((modalRef) => {
              modalRef.content?.onClose.subscribe((onCloseResult) => {
                if (onCloseResult) {
                  resolve(this.updateInvoiceSession(session, updateTariffs, updateSurcharges, true));
                } else {
                  resolve(false);
                }
              });
            });
          } else {
            resolve(true);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    });
  }

  createServiceProvider(
    serviceProvider: InvoiceSessionServiceProviderDto,
    ignoreWarnings: boolean = false,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      var additionalParams = new HttpParams().set('ignoreWarnings', ignoreWarnings);

      this.invoiceSessionServiceProviderService.create(serviceProvider, additionalParams).subscribe(
        (result) => {
          this.loadingSubject.next(false);

          if (result.warnings.length > 0) {
            this.errorHandler.displayWarningDialog(result, undefined, 'Global.SaveAnyway').then((modalRef) => {
              modalRef.content?.onClose.subscribe((onCloseResult) => {
                if (onCloseResult) {
                  resolve(this.createServiceProvider(serviceProvider, true));
                } else {
                  resolve(false);
                }
              });
            });
          } else {
            resolve(true);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    });
  }

  updateServiceProvider(
    serviceProvider: InvoiceSessionServiceProviderDto,
    ignoreWarnings: boolean = false,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      var additionalParams = new HttpParams().set('ignoreWarnings', ignoreWarnings);

      this.invoiceSessionServiceProviderService.update(serviceProvider, additionalParams).subscribe(
        (result) => {
          this.loadingSubject.next(false);

          if (result.warnings.length > 0) {
            this.errorHandler.displayWarningDialog(result, undefined, 'Global.SaveAnyway').then((modalRef) => {
              modalRef.content?.onClose.subscribe((onCloseResult) => {
                if (onCloseResult) {
                  resolve(this.updateServiceProvider(serviceProvider, true));
                } else {
                  resolve(false);
                }
              });
            });
          } else {
            resolve(true);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    });
  }

  openInvoicePdf(): void {
    this.delegationService.delegator$.pipe(first()).subscribe((delegator) => {
      this.invoiceService.openInvoicePdf(this.invoice.id, delegator);
    });
  }

  showSendInvoiceDialog(): void {
    this.institution$.subscribe((institution) => {
      TextDialogComponent.showDialogTranslated(
        this.translate,
        this.modalService,
        'User.Invoice.SendInvoice',
        this.translate.instant('User.Invoice.SendInvoiceDialogText') +
          (institution?.institutionConfiguration.isInvoiceSentToExternalControlling
            ? '<br/><br/>' +
              this.translate.instant('User.Invoice.SendInvoiceDialogWithExternalControllingText', {
                institutionName: institution.title,
              })
            : ''),
        'Global.Ok',
        'Global.Cancel',
      ).then((modalRef) => {
        modalRef.content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult) {
            this.sendInvoice(false).then((result) => {
              if (result) {
                this.loadData();
              }
            });
          }
        });
      });
    });
  }

  sendInvoice(ignoreWarnings: boolean = false): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.loadingSubject.next(true);

      this.invoiceService.send(this.invoice.id).subscribe(
        (result) => {
          this.loadingSubject.next(false);

          if (result.warnings.length > 0) {
            this.errorHandler.displayWarningDialog(result, undefined, 'Global.SaveAnyway').then((modalRef) => {
              modalRef.content?.onClose.subscribe((onCloseResult) => {
                if (onCloseResult) {
                  resolve(this.sendInvoice(true));
                } else {
                  resolve(false);
                }
              });
            });
          } else {
            resolve(true);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    });
  }

  cloneInvoice(): void {
    this.invoiceService.clone(this.invoice.id).subscribe(
      (result) => {
        if (result.success) {
          this.router.navigate(['/user/invoice', result.value]);
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  editInvoice(): void {
    this.translate.get(['User.Invoices.EditInvoice', 'Global.Save', 'Global.Cancel']).subscribe((res) => {
      this.modalService
        .show<RowEditDialogComponent<InvoiceDto, number, any, any>>(RowEditDialogComponent, {
          class: 'modal-1000',
          ignoreBackdropClick: true,
          initialState: {
            containerType: EditInvoiceContainerComponent,
            config: undefined,
            dataSource: this.invoiceDataSource,
            id: this.invoice.id,
            additionalParams: undefined,
            preActionData: undefined,
            dialogConfig: {
              title: res['User.Invoices.EditInvoice'],
              button_confirm_text: res['Global.Save'],
              button_cancel_text: res['Global.Cancel'],
            },
          },
        })
        .content?.onClose.subscribe((onCloseResult) => {
          if (onCloseResult.confim) {
            this.loadingSubject.next(true);
            if (onCloseResult.data) {
              this.invoiceDataSource.update(onCloseResult.data).subscribe(
                (updateResult) => {
                  onCloseResult.modalRef.hide();
                  this.loadData();
                },
                (errorResponse: HttpErrorResponse) => {
                  this.loadingSubject.next(false);
                  this.errorHandler.displayErrorDialog(errorResponse);
                },
              );
            }
          }
        });
    });
  }
}
