import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { ApproveInvoiceDto } from '@app/_models/approveInvoiceDto';
import { CommandResult } from '@app/_models/command/commandResult';
import { DeclineInvoiceDto } from '@app/_models/delineInvoiceDto';
import { InvoiceForControllingDto } from '@app/_models/invoiceForControllingDto';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceControllingService extends BaseTableDataService<InvoiceForControllingDto, number> {
  constructor(
    protected http: HttpClient,
    public translate: TranslateService,
  ) {
    super('invoiceControlling', '', http);
  }

  approve(invoiceId: number): Observable<CommandResult> {
    var approveInvoiceDto: ApproveInvoiceDto = { invoiceId: invoiceId };
    return this.http.post<CommandResult>(this.baseControllerUrl + '/approve', approveInvoiceDto);
  }

  decline(invoiceId: number, declineReason: string): Observable<CommandResult> {
    var declineInvoiceDto: DeclineInvoiceDto = { invoiceId: invoiceId, declineReason: declineReason };
    return this.http.post<CommandResult>(this.baseControllerUrl + '/decline', declineInvoiceDto);
  }
}
