import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { zip } from 'rxjs';

import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { RowAction, RowActionEnum, RowActionPreActionResult } from '@app/_controls/data-table/actions/rowAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { EditSettings } from '@app/_controls/data-table/settings/editSettings';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { InMemoryDataSource } from '@app/_datasources/inMemory.datasource';
import { IRowEditContainer } from '@app/_dialogs/row-edit-dialog/containers/row-edit-container.interface';
import { formatDateYYYYMMDD } from '@app/_helpers/functions/date-functions';
import { BooleanTransformPipe } from '@app/_helpers/transform/boolean.transform';
import { showInvoiceText } from '@app/_helpers/transform/catalog-item-invoice-text.transform';
import { CatalogItemDto } from '@app/_models/catalogItemDto';
import { CatalogViewDto } from '@app/_models/catalogViewDto';
import { CatalogItemType } from '@app/_models/enums/catalogItemType';
import { InvoiceSessionDto } from '@app/_models/invoiceSessionDto';
import { InvoiceSessionPositionDto } from '@app/_models/invoiceSessionPositionDto';
import { CatalogService } from '@app/_services/catalog.service';
import { CatalogViewService } from '@app/_services/catalogView.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InvoiceMultiplierService } from '@app/_services/invoiceMultiplier.service';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { RowEditDialogComponent } from '../../row-edit-dialog.component';
import { EditInvoiceSessionContainerConfig } from './editInvoiceSessionContainerConfig';
import { EditInvoiceSessionContainerResultData } from './editInvoiceSessionContainerResultData';

@Component({
  selector: 'app-edit-invoice-session-container',
  templateUrl: './edit-invoice-session-container.component.html',
  styleUrls: ['./edit-invoice-session-container.component.css'],
})
export class EditInvoiceSessionContainerComponent
  implements
    IRowEditContainer<
      InvoiceSessionDto,
      number,
      EditInvoiceSessionContainerResultData,
      EditInvoiceSessionContainerConfig
    >
{
  dialog!: RowEditDialogComponent<InvoiceSessionDto, number, any, EditInvoiceSessionContainerConfig>;
  data!: InvoiceSessionDto;
  initialData?: InvoiceSessionDto;
  resultData?: EditInvoiceSessionContainerResultData;
  form!: UntypedFormGroup;
  config!: EditInvoiceSessionContainerConfig;
  preActionData?: RowActionPreActionResult;
  preConfirmAction: undefined;
  getMostUsed: boolean = false;

  invoiceMultipliers: Map<number, string> = new Map<number, string>();
  catalogView!: CatalogViewDto;

  invoiceSurchargePositionsBaseTableDataSource?: BaseTableDataServiceDataSource<CatalogItemDto, number>;
  invoiceSurchargePositionsInMemoryDataSource?: InMemoryDataSource<InvoiceSessionPositionDto, number>;

  invoiceTariffPositionsBaseTableDataSource?: BaseTableDataServiceDataSource<CatalogItemDto, number>;
  invoiceTariffPositionsInMemoryDataSource?: InMemoryDataSource<InvoiceSessionPositionDto, number>;

  @ViewChild('sessionDateInput') sessionDateInputComponent!: BsDaterangepickerDirective;
  @ViewChild('surchargePositions') surchargePositionsTableComponent!: DataTableComponent<CatalogItemDto, number>;
  @ViewChild('invoiceSurchargePositions') invoiceSurchargePositionsTableComponent!: DataTableComponent<
    InvoiceSessionPositionDto,
    number
  >;
  @ViewChild('tariffPositions') tariffPositionsDataTableComponent!: DataTableComponent<CatalogItemDto, number>;
  @ViewChild('invoiceTariffPositions') invoiceTariffPositionsTableComponent!: DataTableComponent<
    InvoiceSessionPositionDto,
    number
  >;

  private positionsSearchColumns = ['column01', 'column03', 'column04', 'title'];
  private leftTableDivStyle = 'overflow: auto; height: 400px;';
  private rightTableDivStyle = 'overflow: auto; height: 456px;';

  public surchargePositionsTableColumns: Array<TableColumn> = [
    {
      columnProperty: 'column01',
      header: 'Global.Number',
      flex: '0 0 100px',
    },
    {
      columnProperty: 'title',
      displayFunction: (element: string, row: CatalogItemDto) => showInvoiceText(row),
      header: 'Global.Title',
      maxTextLength: 60,
      cutTextAtNewLine: true,
    },
    {
      columnProperty: 'value',
      header: 'Global.Value',
      flex: '0 0 100px',
    },
  ];

  public invoiceSurchargePositionTableColumns: Array<TableColumn> = [
    {
      columnProperty: 'catalogItem.column01',
      header: 'Global.Number',
      flex: '0 0 100px',
    },
    {
      columnProperty: 'catalogItem.title',
      displayFunction: (element: string, row: InvoiceSessionPositionDto) => showInvoiceText(row.catalogItem),
      header: 'Global.Title',
      maxTextLength: 60,
      cutTextAtNewLine: true,
    },
    {
      columnProperty: 'surchargeCount',
      header: 'Global.Count',
      editSettings: EditSettings.withMask('90', []),
      flex: '0 0 100px',
    },
  ];

  public tariffPositionsTableColumns: Array<TableColumn> = [
    {
      columnProperty: 'column01',
      header: 'Global.Number',
      flex: '0 0 100px',
    },
    {
      columnProperty: 'title',
      displayFunction: (element: string, row: CatalogItemDto) => showInvoiceText(row),
      header: 'Global.Title',
      maxTextLength: 60,
      cutTextAtNewLine: true,
    },
    {
      columnProperty: 'value',
      header: 'Global.Value',
      flex: '0 0 100px',
    },
  ];

  public invoiceTariffPositionsTableColumns: Array<TableColumn> = [
    {
      columnProperty: 'isMainItem',
      header: 'Global.MainPerformance',
      valueMap: BooleanTransformPipe.booleanMap,
      displayTranslate: true,
      flex: '0 0 115px',
    },
    {
      columnProperty: 'catalogItem.column01',
      header: 'Global.Number',
      flex: '0 0 100px',
    },
    {
      columnProperty: 'catalogItem.title',
      header: 'Global.Title',
      displayFunction: (element: string, row: InvoiceSessionPositionDto) => showInvoiceText(row.catalogItem),
      maxTextLength: 60,
      cutTextAtNewLine: true,
    },
    {
      columnProperty: 'multiplier',
      header: 'Global.PerformanceEvaluation',
      valueMap: this.invoiceMultipliers,
      editSettings: EditSettings.withSelect(),
      flex: '0 0 200px',
    },
  ];

  public surchargePositionsRowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Custom,
      tooltip: 'Global.Choose',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-plus fa-lg',
      actionParams: {
        customFunction: this.addSurchargePosition.bind(this),
      },
    },
  ];

  public invoiceSurchargePositionsRowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Custom,
      tooltip: 'Global.Remove',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-trash fa-lg',
      actionParams: {
        customFunction: this.removeSurchargePosition.bind(this),
      },
    },
  ];

  public tariffPositionsRowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Custom,
      tooltip: 'Global.Choose',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-plus fa-lg',
      actionParams: {
        customFunction: this.addTariffPosition.bind(this),
      },
    },
  ];

  public invoiceTariffPositionsRowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Custom,
      tooltip: 'Global.Remove',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-trash fa-lg',
      actionParams: {
        customFunction: this.removeTariffPosition.bind(this),
      },
    },
    {
      action: RowActionEnum.Custom,
      tooltip: 'Dialog.EditInvoiceSessionContainerComponent.SetMainItem',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'blue fa fa-asterisk fa-lg',
      actionParams: {
        customFunction: this.setMainItem.bind(this),
      },
    },
  ];

  constructor(
    private errorHandler: ErrorHandlerService,
    public catalogService: CatalogService,
    public catalogViewService: CatalogViewService,
    public invoiceMultiplierService: InvoiceMultiplierService,
  ) {}

  initializeContainer(): void {
    if (!this.data) {
      if (this.initialData) {
        this.data = this.initialData;
      } else {
        this.data = <InvoiceSessionDto>{};
      }
    }

    if (!this.config.editInvoice) {
      this.leftTableDivStyle = 'overflow: auto; height: 500px;';
      this.rightTableDivStyle = 'overflow: auto; height: 556px;';
    }

    this.resultData = <EditInvoiceSessionContainerResultData>{
      deletedSurchargePositions: new Array<InvoiceSessionPositionDto>(),
      deletedTariffPositions: new Array<InvoiceSessionPositionDto>(),
    };

    this.form = new UntypedFormGroup({
      date: new UntypedFormControl(this.data.date, [Validators.required]),
    });

    this.form.valueChanges.subscribe((changes) => {
      for (let key of Object.keys(changes)) {
        this.data[key] = changes[key];
      }
    });

    // Trigger form validation now.
    this.form.markAllAsTouched();

    this.loadData();
  }

  ngAfterViewInit(): void {
    // Open date picker directly when adding invoice session.
    if (this.config.editInvoice) {
      this.sessionDateInputComponent.show();
    }
  }

  get f() {
    return this.form.controls;
  }

  loadData(): void {
    if (this.config) {
      this.dialog.loadingSubject.next(true);

      var getCatalogView = this.catalogViewService.get(this.config.catalogViewId);
      var getInvoiceMultipliers = this.invoiceMultiplierService.getInvoiceMultipliers();

      zip(getCatalogView, getInvoiceMultipliers).subscribe(
        (result) => {
          this.dialog.loadingSubject.next(false);

          this.catalogView = result[0];

          result[1].forEach((multiplier) => {
            this.invoiceMultipliers.set(multiplier.value, multiplier.title);
          });

          this.loadCatalog();
        },
        (errorResponse: HttpErrorResponse) => {
          this.dialog.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    }
  }

  loadCatalog(): void {
    var catalogValidityDateString = formatDateYYYYMMDD(this.config.catalogValidityDate);

    var catalogViewParams = new HttpParams().set('catalogViewId', this.config.catalogViewId);

    // When component is first loaded dataTableComponent is null, so we load in ngAfterViewInit().
    if (this.surchargePositionsTableComponent) {
      this.invoiceSurchargePositionsBaseTableDataSource = new BaseTableDataServiceDataSource<CatalogItemDto, number>(
        this.catalogService,
        this.errorHandler,
      );

      let surchargesBaseFilter =
        `catalogId==${this.config.catalogId}` +
        `&(type==${CatalogItemType.Surcharge}|type==${CatalogItemType.FlatRateSurcharge})` +
        `&dontShowInInvoice==false&validFrom<=${catalogValidityDateString}&(validUntil==null|validUntil>=${catalogValidityDateString})`;

      if (this.catalogView.filter) {
        surchargesBaseFilter += `&(${this.catalogView.filter})`;
      }

      let surchargesTableSettings = <TableSettings<CatalogItemDto, number>>{
        dataSource: this.invoiceSurchargePositionsBaseTableDataSource,
        tableColumns: this.surchargePositionsTableColumns,
        rowActions: this.surchargePositionsRowActions,
        displayedColumns: this.surchargePositionsTableColumns.map((x) => x.columnProperty),
        getAdditionalParams: catalogViewParams,
        baseFilter: surchargesBaseFilter,
        tableDivStyle: this.leftTableDivStyle,
        globalFilterColumns: this.positionsSearchColumns,
      };

      this.surchargePositionsTableComponent.tableSettings = surchargesTableSettings;
      this.surchargePositionsTableComponent.loadData();
    }

    if (this.invoiceSurchargePositionsTableComponent) {
      this.invoiceSurchargePositionsInMemoryDataSource = new InMemoryDataSource<InvoiceSessionPositionDto, number>(
        this.data.surcharges,
      );

      let invoiceSurchargePositionsTableSettings = <TableSettings<InvoiceSessionPositionDto, number>>{
        dataSource: this.invoiceSurchargePositionsInMemoryDataSource,
        tableColumns: this.invoiceSurchargePositionTableColumns,
        rowActions: this.invoiceSurchargePositionsRowActions,
        displayedColumns: this.invoiceSurchargePositionTableColumns.map((x) => x.columnProperty),
        tableDivStyle: this.rightTableDivStyle,
        hideToolbar: true,
        disablePaging: true,
      };

      this.invoiceSurchargePositionsTableComponent.tableSettings = invoiceSurchargePositionsTableSettings;
      this.invoiceSurchargePositionsTableComponent.loadData();
    }

    if (this.tariffPositionsDataTableComponent) {
      this.invoiceTariffPositionsBaseTableDataSource = new BaseTableDataServiceDataSource<CatalogItemDto, number>(
        this.catalogService,
        this.errorHandler,
      );

      var tariffPositionsBaseFilter = `catalogId==${this.config.catalogId}&(type==0|type==3)&dontShowInInvoice==false&validFrom<=${catalogValidityDateString}&(validUntil==null|validUntil>=${catalogValidityDateString})`;

      if (this.catalogView.filter) {
        tariffPositionsBaseFilter += `&(${this.catalogView.filter})`;
      }

      let tariffPositionsTableSettings = <TableSettings<CatalogItemDto, number>>{
        dataSource: this.invoiceTariffPositionsBaseTableDataSource,
        tableColumns: this.tariffPositionsTableColumns,
        rowActions: this.tariffPositionsRowActions,
        displayedColumns: this.tariffPositionsTableColumns.map((x) => x.columnProperty),
        getAdditionalParams: catalogViewParams,
        baseFilter: tariffPositionsBaseFilter,
        tableDivStyle: this.leftTableDivStyle,
        globalFilterColumns: this.positionsSearchColumns,
        baseColumnSort: [
          { column: 'type', direction: 'desc' },
          { column: 'column01', direction: 'asc' },
        ],
      };

      this.tariffPositionsDataTableComponent.tableSettings = tariffPositionsTableSettings;
      this.tariffPositionsDataTableComponent.loadData();
    }

    if (this.invoiceTariffPositionsTableComponent) {
      this.invoiceTariffPositionsInMemoryDataSource = new InMemoryDataSource<InvoiceSessionPositionDto, number>(
        this.data.tariffPositions,
      );

      let invoiceTariffPositionsTableSettings = <TableSettings<InvoiceSessionPositionDto, number>>{
        dataSource: this.invoiceTariffPositionsInMemoryDataSource,
        tableColumns: this.invoiceTariffPositionsTableColumns,
        rowActions: this.invoiceTariffPositionsRowActions,
        displayedColumns: this.invoiceTariffPositionsTableColumns.map((x) => x.columnProperty),
        tableDivStyle: this.rightTableDivStyle,
        hideToolbar: true,
        disablePaging: true,
        baseColumnSort: [{ column: 'isMainItem', direction: 'desc' }],
      };

      this.invoiceTariffPositionsTableComponent.tableSettings = invoiceTariffPositionsTableSettings;
      this.invoiceTariffPositionsTableComponent.loadData();
    }
  }

  addSurchargePosition(element: CatalogItemDto): void {
    if (this.invoiceSurchargePositionsInMemoryDataSource && element.id) {
      var elementExists = this.invoiceSurchargePositionsInMemoryDataSource.paginationData?.data.find(
        (x) => x.catalogItemId == element.id,
      );

      // Each catalog item can only added once.
      if (elementExists) {
        return;
      }

      var invoiceSurchargePosition: InvoiceSessionPositionDto = {
        id: 0,
        catalogItem: element,
        catalogItemId: element.id,
        sessionId: this.data.id,
        isMainItem: false,
        multiplier: 0,
        surchargeCount: 1,
      };

      this.invoiceSurchargePositionsInMemoryDataSource.create(invoiceSurchargePosition).subscribe();
    }
  }

  removeSurchargePosition(element: InvoiceSessionPositionDto): void {
    if (this.invoiceSurchargePositionsInMemoryDataSource) {
      this.invoiceSurchargePositionsInMemoryDataSource.delete(element).subscribe();
      this.resultData?.deletedSurchargePositions.push(element);
    }
  }

  addTariffPosition(element: CatalogItemDto): void {
    if (this.invoiceTariffPositionsInMemoryDataSource && element.id) {
      var isMainItem = this.invoiceTariffPositionsInMemoryDataSource.totalCount == 0;

      var invoiceTariffPosition: InvoiceSessionPositionDto = {
        id: 0,
        catalogItem: element,
        catalogItemId: element.id,
        sessionId: this.data.id,
        isMainItem: isMainItem,
        multiplier: isMainItem || element.title.startsWith('+') ? 1 : 0.5,
        surchargeCount: 0,
      };

      this.invoiceTariffPositionsInMemoryDataSource.create(invoiceTariffPosition).subscribe();
    }
  }

  removeTariffPosition(element: InvoiceSessionPositionDto): void {
    if (this.invoiceTariffPositionsInMemoryDataSource) {
      this.invoiceTariffPositionsInMemoryDataSource.delete(element).subscribe();
      this.resultData?.deletedTariffPositions.push(element);

      // If main position is deleted set next position as main item.
      if (element.isMainItem && this.data.tariffPositions.length > 0) {
        this.data.tariffPositions[0].isMainItem = true;
      }
    }
  }

  setMainItem(element: CatalogItemDto): void {
    for (var tariffPostion of this.data.tariffPositions) {
      tariffPostion.isMainItem = tariffPostion.catalogItem.id == element.catalogItemId;
    }

    this.invoiceTariffPositionsInMemoryDataSource?.refreshData();
  }

  toggleGetMostUsed(): void {
    this.getMostUsed = !this.getMostUsed;

    if (this.tariffPositionsDataTableComponent) {
      this.invoiceTariffPositionsBaseTableDataSource?.setAdditionalParams(
        new HttpParams().set('getMostUsed', this.getMostUsed),
      );
      this.tariffPositionsDataTableComponent.loadData();
    }

    if (this.surchargePositionsTableComponent) {
      this.invoiceSurchargePositionsBaseTableDataSource?.setAdditionalParams(
        new HttpParams().set('getMostUsed', this.getMostUsed),
      );
      this.surchargePositionsTableComponent.loadData();
    }
  }
}
