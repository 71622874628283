import { Injectable } from '@angular/core';
import { Delegator } from '@app/_models/delegationDto';
import { AccountService } from '@app/_services/account.service';
import { UserService } from '@app/_services/user.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

@Injectable()
export class DelegationService {
  private readonly STORAGE_KEY = 'delegatorUserId';

  private delegatorSource = new BehaviorSubject<string | Delegator | undefined>(
    localStorage.getItem(this.STORAGE_KEY) ?? undefined,
  );

  private readonly delegations$ = this.accountService.currentUser$.pipe(
    switchMap((currentUser) => (currentUser ? this.userService.getDelegations() : of(undefined))),
    shareReplay(1),
  );

  public readonly delegators$ = this.delegations$.pipe(
    map((delegations) => {
      delegations?.forEach(
        (delegation) =>
          (delegation.delegators = delegation.delegators.filter((delegator) => delegator.delegatorUserId)),
      );
      return delegations ?? [];
    }),
    map((delegations) => delegations?.flatMap((delegation) => delegation.delegators) ?? []),
  );

  public readonly delegator$: Observable<Delegator | undefined> = this.delegatorSource.pipe(
    switchMap((source) => {
      if (!source) {
        return of(undefined);
      }
      if (typeof source !== 'string') {
        return of(source);
      }
      return this.delegators$.pipe(
        map((delegators) => delegators.find((delegator) => delegator.delegatorUserId === source)),
      );
    }),
    shareReplay(1),
  );

  constructor(
    private userService: UserService,
    private accountService: AccountService,
  ) {}

  setDelegator(value: Delegator | undefined) {
    if (value && value.delegatorUserId) {
      localStorage.setItem(this.STORAGE_KEY, value.delegatorUserId);
      this.delegatorSource.next(value);
    } else {
      localStorage.removeItem(this.STORAGE_KEY);
      this.delegatorSource.next(undefined);
    }
  }
}
