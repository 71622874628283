import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { round } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, zip } from 'rxjs';

import { CustomRowActionParams } from '@app/_controls/data-table/actions/customRowActionParams';
import { NavigateActionParams } from '@app/_controls/data-table/actions/navigateActionParams';
import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { CustomFormDialogComponent } from '@app/_dialogs/custom-form-dialog/custom-form-dialog.component';
import { CustomFormDialogConfig } from '@app/_dialogs/custom-form-dialog/custom-form-dialog.config';
import { EditInvoiceContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-invoice-container/edit-invoice-container.component';
import { DownloadFileDirective } from '@app/_helpers/directives/download-file.directive';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { InvoiceStatusTransformPipe } from '@app/_helpers/transform/invoice-status.transform';
import { Delegator } from '@app/_models/delegationDto';
import { InvoiceStatus } from '@app/_models/enums/invoiceStatus';
import { InstitutionLink } from '@app/_models/institutionDto';
import { InvoiceDto } from '@app/_models/invoiceDto';
import { InvoicesExcelExportDto } from '@app/_models/InvoicesExcelExportDto';
import { PatientDto } from '@app/_models/patientDto';
import { AccountService } from '@app/_services/account.service';
import { ApplicationService } from '@app/_services/application.service';
import { ContractService } from '@app/_services/contract.service';
import { DelegationService } from '@app/_services/delegation.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { InvoiceService } from '@app/_services/invoice.service';
import { PatientService } from '@app/_services/patient.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-user-invoices',
  templateUrl: './user-invoices.component.html',
  styleUrls: ['./user-invoices.component.scss'],
})
export class UserInvoicesComponent implements OnInit, AfterViewInit {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  public patient?: PatientDto;

  private contractTitleMap: Map<number, string> = new Map<number, string>();

  isDelegate = false;
  delegators: Delegator[] = [];

  private invoiceNavigateActionParams: NavigateActionParams = {
    navigateCommandsFunction: (element: InvoiceDto) => {
      return ['user/invoice', element.id];
    },
  };

  private invoiceIdColumn: TableColumn = {
    columnProperty: 'id',
    header: 'Global.Id',
    searchType: 'Number',
    flex: '0 0 100px',
  };

  private defaultTableColumns: Array<TableColumn> = [
    {
      columnProperty: 'createDate',
      header: 'Global.InvoiceDate',
      displayFunction: (element: Date, row: InvoiceDto) => formatDateOnly(element),
      searchType: 'DateRange',
      flex: '0 0 150px',
    },
    {
      columnProperty: 'dateFrom',
      header: 'Global.HospitalizationFrom',
      displayFunction: (element: Date, row: InvoiceDto) => formatDateOnly(element),
      searchType: 'DateRange',
      flex: '0 0 165px',
    },
    {
      columnProperty: 'dateTo',
      header: 'Global.HospitalizationUntil',
      displayFunction: (element: Date, row: InvoiceDto) => formatDateOnly(element),
      searchType: 'DateRange',
      flex: '0 0 165px',
    },
    {
      columnProperty: 'status',
      header: 'Global.State',
      searchType: 'Selection',
      valueMap: InvoiceStatusTransformPipe.statusMap,
      displayTranslate: true,
      flex: '0 0 100px',
    },
    {
      columnProperty: 'contractId',
      header: 'Global.Contract',
      searchType: 'Selection',
      valueMap: this.contractTitleMap,
      flex: '0 0 250px',
    },
    {
      columnProperty: 'total',
      header: 'Global.TotalTaxPoints',
      searchType: 'Number',
      displayFunction: (element: number, row: InvoiceDto) => round(element, 2),
      flex: '0 0 150px',
    },
    {
      columnProperty: 'caseNumber',
      header: 'Global.CaseNumber',
      searchType: 'Text',
      flex: '0 0 150px',
    },
    {
      columnProperty: 'pid',
      header: 'Global.PID',
      searchType: 'Text',
      flex: '0 0 150px',
    },
    {
      columnProperty: 'memo',
      header: 'Global.Memo',
      searchType: 'Text',
      flex: '0 0 150px',
    },
  ];

  private invoicePatientTableColumns: Array<TableColumn> = [
    {
      columnProperty: 'patientFirstName',
      header: 'Global.FirstName',
      flex: '0 0 150px',
      disableSort: true, // These columns are not on the entity directly, so not sortable per default
    },
    {
      columnProperty: 'patientLastName',
      header: 'Global.LastName',
      flex: '0 0 150px',
      disableSort: true,
    },
    {
      columnProperty: 'patientDateOfBirth',
      header: 'Global.DateOfBirth',
      flex: '0 0 150px',
      disableSort: true,
      displayFunction: (element: Date, row: PatientDto) => formatDateOnly(element),
    },
  ];

  public patientTableColumns: Array<TableColumn> = [this.invoiceIdColumn, ...this.defaultTableColumns];

  public invoicesTableColumns: Array<TableColumn> = [
    this.invoiceIdColumn,
    ...this.invoicePatientTableColumns,
    ...this.defaultTableColumns,
  ];

  openInvoicePdfActionParams: CustomRowActionParams = {
    customFunction: this.openInvoicePdf.bind(this),
  };

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Delete,
      tooltip: 'Global.Delete',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-trash fa-lg',
      hideCondition: (element: InvoiceDto) => {
        return element.status != InvoiceStatus.Draft;
      },
    },
    {
      action: RowActionEnum.Edit,
      tooltip: 'Global.Edit',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-pencil-alt fa-lg',
      hideCondition: (element: InvoiceDto) => {
        return element.status != InvoiceStatus.Draft;
      },
    },
    {
      action: RowActionEnum.Navigate,
      tooltip: 'Global.Details',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'blue fa fa-search-plus fa-lg',
      actionParams: this.invoiceNavigateActionParams,
    },
    {
      action: RowActionEnum.Custom,
      actionParams: this.openInvoicePdfActionParams,
      tooltip: 'Invoice.Controlling.ShowInvoice',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-file-pdf fa-lg',
    },
  ];

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<InvoiceDto, number>;
  public institutionLinks?: InstitutionLink[];

  constructor(
    public invoiceService: InvoiceService,
    public patientService: PatientService,
    public institutionService: InstitutionService,
    private applicationService: ApplicationService,
    public contractService: ContractService,
    public route: ActivatedRoute,
    public router: Router,
    private errorHandler: ErrorHandlerService,
    private modalService: BsModalService,
    private oauthService: OAuthService,
    public translate: TranslateService,
    public accountService: AccountService,
    public delegationService: DelegationService,
  ) {}

  ngOnInit(): void {
    this.applicationService.showInvoiceInfoDialog();

    this.initializeDelegationSelection();
  }

  onValueChange(value: Delegator) {
    this.delegationService.setDelegator(value);

    // refresh table and institution link
    this.loadTable();
    this.reloadInstitutionLinks();
  }

  ngAfterViewInit(): void {
    this.reloadInstitutionLinks();

    var getAllContractTypes = this.contractService.getAllContractTypes();

    var getPatient: Observable<PatientDto> = new Observable((observer) => {
      observer.next(undefined);
    });

    if (this.route.snapshot.params.patientid) {
      getPatient = this.patientService.get(this.route.snapshot.params.patientid);
    }

    zip(getPatient, getAllContractTypes).subscribe(
      (result) => {
        this.patient = result[0];

        result[1].forEach((element) => {
          this.contractTitleMap.set(element.id, element.title);
        });

        this.loadingSubject.next(false);
        this.loadTable();
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  private reloadInstitutionLinks() {
    this.institutionService
      .getUserChildInstitutions()
      .pipe(
        first(),
        map((institutions) =>
          Array.from(
            new Map(
              institutions
                .filter((i) => i?.institutionConfiguration?.invoiceInstitutionLink)
                .map((i) => i.institutionConfiguration.invoiceInstitutionLink!)
                .map((link) => [link.title, link]),
            ).values(),
          ),
        ),
      )
      .subscribe((links) => {
        this.institutionLinks = links;
      });
  }

  loadTable(): void {
    var baseFilter = undefined;
    var rowEditContainerConfig = undefined;

    if (this.patient) {
      baseFilter = `patientId==${this.patient.id}`;
      rowEditContainerConfig = { patientId: this.patient.id };
    }

    let tableSettings = <TableSettings<InvoiceDto, number>>{
      dataSource: new BaseTableDataServiceDataSource<InvoiceDto, number>(this.invoiceService, this.errorHandler),
      rowEditContainer: EditInvoiceContainerComponent,
      rowEditContainerConfig: rowEditContainerConfig,
      rowEditContainerStyle: 'modal-1000',
      addRowTitle: 'User.Invoices.AddInvoice',
      editRowTitle: 'User.Invoices.EditInvoice',
      deleteRowTitle: 'User.Invoices.DeleteInvoice',
      deleteRowText: 'User.Invoices.DeleteInvoiceText',
      tableColumns: this.patient ? this.patientTableColumns : this.invoicesTableColumns,
      rowActions: this.rowActions,
      baseFilter: baseFilter,
      baseColumnSort: [{ column: 'createDate', direction: 'desc' }],
    };

    this.dataTableComponent.tableSettings = tableSettings;
    this.dataTableComponent.loadData();
  }

  openInvoicePdf(element: InvoiceDto): void {
    this.delegationService.delegator$.pipe(first()).subscribe((delegator) => {
      this.invoiceService.openInvoicePdf(element.id, delegator);
    });
  }

  showExcelExportDialog(): void {
    var dateRangeDialogConfig = CustomFormDialogConfig.getDefaultDateRangeConfig({
      dialogTitle: 'Global.ExcelExport',
      fieldLabel: 'Global.SelectDateRange',
      modalClass: 'modal-800',
      required: true,
    });

    var modalRef = CustomFormDialogComponent.showDialog(this.modalService, dateRangeDialogConfig);

    modalRef.content?.onClose.subscribe((onCloseResult) => {
      if (onCloseResult.confirm && onCloseResult.data.dateRange) {
        let invoicesExcelExportDto: InvoicesExcelExportDto = {
          fromDate: onCloseResult.data.dateRange[0],
          untilDate: onCloseResult.data.dateRange[1],
        };

        // Download the document as a blob
        this.invoiceService.excelExport(invoicesExcelExportDto).subscribe(
          (response: any) => {
            DownloadFileDirective.downloadFileFromHttpResponse(response);
          },
          (errorResponse: HttpErrorResponse) => {
            this.errorHandler.displayErrorDialog(errorResponse);
          },
        );
      }
    });
  }

  private initializeDelegationSelection() {
    this.accountService.hasAnyRole(['Delegate']).subscribe((hasDelegateRole) => {
      this.isDelegate = hasDelegateRole;

      if (!this.isDelegate) {
        return;
      }

      zip(this.delegationService.delegators$).subscribe(([delegators]) => (this.delegators = delegators));
    });
  }
}
