import { Component, OnInit } from '@angular/core';
import { NgxTranslateDebugService } from 'ngx-translate-debug';

import { InstitutionIdentityProvider } from '@app/_models/institutionDto';
import { AccountService } from '@app/_services/account.service';
import { InstitutionService } from '@app/_services/institution.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { appConfig } from 'config/appConfig';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  public isNavbarMenuCollapsed = true;
  public headerImageUrl = appConfig.headerImageUrl2;

  isLoggedInWithIDP = false;
  institutionIDPs: InstitutionIdentityProvider[] = [];
  private sessionState?: string;

  constructor(
    public accountService: AccountService,
    public translateDebugService: NgxTranslateDebugService,
    public oauthService: OAuthService,
    public institutionService: InstitutionService,
  ) {}

  ngOnInit(): void {
    this.accountService.currentJwt$.subscribe((decodedToken) => {
      this.isLoggedInWithIDP = !!decodedToken?.identity_provider;
      this.sessionState = decodedToken?.session_state;
    });
    this.institutionService.getInstitutionIdentityProviders().subscribe((identityProviders) => {
      this.institutionIDPs = identityProviders;
    });
  }

  logout() {
    this.accountService.logout();
  }

  changePassword() {
    const searchParams = new URLSearchParams({
      client_id: this.oauthService.clientId ?? '',
      redirect_uri: this.oauthService.redirectUri ?? '',
      response_type: 'code',
      scope: 'openid',
      kc_action: 'UPDATE_PASSWORD',
    });

    window.open(`${this.oauthService.issuer}/protocol/openid-connect/auth?` + searchParams);
  }

  async linkHin() {
    await this.accountService.navigateToLinkingIdp('hin', '/account/hin-linked');
  }

  async linkIdp(identityProvider: string) {
    await this.accountService.linkIdpAndForceLogin(identityProvider);
  }

  toggleNavbarMenu() {
    this.isNavbarMenuCollapsed = !this.isNavbarMenuCollapsed;
  }
}
