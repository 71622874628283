<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>
<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>
      {{ 'User.Invoices.Header' | translate }}
      <span *ngIf="patient">
        {{ 'User.Invoices.ForPatient' | translate }}
        <span style="font-weight: bold" *ngIf="patient.firstName"> {{ patient.firstName }}</span>
        <span style="font-weight: bold" *ngIf="patient.lastName"> {{ patient.lastName }}</span>
      </span>
    </h1>
  </div>
  <div class="header-toolbar">
    <select
      [ngModel]="this.delegationService.delegator$ | async"
      (ngModelChange)="onValueChange($event)"
      *ngIf="isDelegate && !patient"
      class="delegator-select">
      <option [ngValue]="undefined" disabled>{{ 'User.Delegation.CurrentDelegator' | translate }} ...</option>
      <option *ngFor="let delegator of delegators" [ngValue]="delegator">
        {{ delegator.delegatorUserDisplay }}
      </option>
    </select>
    <a
      *ngFor="let institutionLink of institutionLinks"
      class="institution-link"
      target="_blank"
      [href]="institutionLink.href">
      {{ institutionLink.title }}
    </a>
    <button
      *ngIf="!patient"
      (click)="showExcelExportDialog()"
      class="btn btn-xs btn-success"
      [disabled]="isDelegate && (this.delegationService.delegator$ | async) == undefined">
      {{ 'Global.ExcelExport' | translate }}
    </button>
    <a
      routerLink="/user/invoice-create"
      [queryParams]="{ patientId: patient?.id }"
      class="btn btn-xs btn-primary"
      [class.disabled]="isDelegate && (this.delegationService.delegator$ | async) == undefined">
      {{ 'User.Invoices.AddInvoice' | translate }}
    </a>
  </div>
</div>
<div *ngIf="!patient" [innerHTML]="'User.Invoices.InvoicesInfo' | translate | safeHtml" routeTransformer></div>
<app-data-table id="userInvoices"></app-data-table>
