import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PdfViewerDialogComponent } from '@app/_dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { CloneInvoiceDto } from '@app/_models/cloneInvoiceDto';
import { CommandResult } from '@app/_models/command/commandResult';
import { CommandResultWithValue } from '@app/_models/command/commandResultWithValue';
import { Delegator } from '@app/_models/delegationDto';
import { InvoiceDto } from '@app/_models/invoiceDto';
import { InvoicesExcelExportDto } from '@app/_models/InvoicesExcelExportDto';
import { SendInvoiceDto } from '@app/_models/sendInvoiceDto';
import { DelegationInterceptor } from '@app/_providers/delegationinterceptor';
import { TIMEOUT_TOKEN } from '@app/_providers/httpErrorInterceptor';
import { OAuthService } from 'angular-oauth2-oidc';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { BaseTableDataService } from './baseTableData.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends BaseTableDataService<InvoiceDto, number> {
  constructor(
    protected http: HttpClient,
    private translate: TranslateService,
    private modalService: BsModalService,
    private oAuthService: OAuthService,
  ) {
    super('invoice', '', http);
  }

  send(invoiceId: number): Observable<CommandResult> {
    var sendInvoiceDto: SendInvoiceDto = { invoiceId: invoiceId };
    return this.http.post<CommandResult>(this.baseControllerUrl + '/send', sendInvoiceDto);
  }

  clone(invoiceId: number): Observable<CommandResultWithValue<number>> {
    var cloneInvoiceDto: CloneInvoiceDto = { invoiceId: invoiceId };
    return this.http.post<CommandResultWithValue<number>>(this.baseControllerUrl + '/clone', cloneInvoiceDto);
  }

  acceptTerms(): Observable<CommandResult> {
    return this.http.post<CommandResult>(this.baseControllerUrl + '/acceptTerms', undefined);
  }

  excelExport(insuranceContractChangesExcelExportDto: InvoicesExcelExportDto): Observable<HttpResponse<Blob>> {
    return this.http.post(this.baseControllerUrl + '/excelexport', insuranceContractChangesExcelExportDto, {
      responseType: 'blob',
      observe: 'response',
      context: new HttpContext().set(TIMEOUT_TOKEN, 300000),
    });
  }

  openInvoicePdf(invoiceId: number, delegator?: Delegator): void {
    this.translate.get('Global.Invoice').subscribe((res) => {
      PdfViewerDialogComponent.showDialog(
        this.modalService,
        `${res} ${invoiceId}`,
        `/api/invoice/pdf/${invoiceId}` +
          (delegator ? `?${DelegationInterceptor.delegatorUserIdRequestParam}=${delegator.delegatorUserId}` : ''),
        this.oAuthService.getAccessToken(),
      );
    });
  }
}
