import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DelegationService } from '@app/_services/delegation.service';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

@Injectable()
export class DelegationInterceptor implements HttpInterceptor {
  static skipDelegatorInterceptor: HttpContextToken<boolean> = new HttpContextToken(() => false);
  static delegatorUserIdRequestParam = 'delegatorUserId';
  constructor(private delegationService: DelegationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isExternalRequest(req) || req.context.get(DelegationInterceptor.skipDelegatorInterceptor)) {
      return next.handle(req);
    }

    return this.delegationService.delegator$.pipe(
      first(),
      mergeMap((delegatorOrUndefined) => {
        if (delegatorOrUndefined && delegatorOrUndefined.delegatorUserId) {
          const changedReq = req.clone({
            params: req.params.set(
              DelegationInterceptor.delegatorUserIdRequestParam,
              delegatorOrUndefined.delegatorUserId,
            ),
          });
          return next.handle(changedReq);
        } else {
          return next.handle(req);
        }
      }),
    );
  }

  /**
   * Check if this is an "external" request (request with an absolute url, e.g. to the IdP Keycloak).
   * We don't want to add the delegatorUserId to those.
   */
  private isExternalRequest(req: HttpRequest<any>) {
    return !req.url.startsWith('/');
  }
}

function isHttpEvent(event: any): event is HttpEvent<any> {
  return event && typeof event === 'object' && 'type' in event;
}
