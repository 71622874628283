import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseApi.service';

import { CommandResultWithValue } from '@app/_models/command/commandResultWithValue';
import { PermissionObject } from '@app/_models/enums/permissionObject';
import { ObjectPermissionQueryResultDto } from '@app/_models/objectPermissionQueryResultDto';
import { ObjectPermissionsQueryDto } from '@app/_models/objectPermissionsQueryDto';
import { UserService } from '@app/_services/user.service';
import * as Sentry from '@sentry/browser';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseApiService {
  private currentJwt = new BehaviorSubject<DecodedToken | undefined>(undefined);
  currentJwt$ = this.currentJwt.asObservable();
  currentUser$ = this.currentJwt.pipe(map((it) => it?.preferred_username));
  private currentUserRolesSource = new ReplaySubject<string[]>(1);
  currentUserRoles$ = this.currentUserRolesSource.asObservable();
  userRoles?: string[];

  constructor(
    private router: Router,
    private http: HttpClient,
    private oauthService: OAuthService,
    private userService: UserService,
    public translate: TranslateService,
    private localeService: BsLocaleService,
  ) {
    super('account');
    this.currentJwt$.subscribe((token) => {
      Sentry.setUser(
        token
          ? {
              id: token.sub,
            }
          : null,
      );
    });
  }

  register(model: any): Observable<CommandResultWithValue<RegisterAccountResult>> {
    return this.http.post<any>(this.baseControllerUrl + '/register', model);
  }

  queryObjectPermissions(query: ObjectPermissionsQueryDto[]): Observable<ObjectPermissionQueryResultDto[]> {
    return this.http.post<ObjectPermissionQueryResultDto[]>(this.baseControllerUrl + '/objectpermissions/query', query);
  }

  getObjectPermission(
    permissionObject: PermissionObject,
    objectId: number,
  ): Observable<ObjectPermissionQueryResultDto> {
    const params = new HttpParams().set('permissionObject', permissionObject).set('objectId', objectId);

    return this.http.get<ObjectPermissionQueryResultDto>(this.baseControllerUrl + '/objectpermission', { params });
  }

  setUsernameFromToken() {
    if (this.oauthService.hasValidAccessToken()) {
      const jwtHelper = new JwtHelperService();
      const decodedToken: any = jwtHelper.decodeToken(this.oauthService.getAccessToken());
      this.currentJwt.next(decodedToken);
    } else {
      this.logout();
    }
  }

  private readonly _postLoginRedirectPath = 'post_login_redirect_path';

  updateUserRoles() {
    if (this.oauthService.hasValidAccessToken()) {
      this.userService.getUserRoles().subscribe((roles) => {
        this.userRoles = roles;
        this.currentUserRolesSource.next(this.userRoles);
        this.hasRole('UserRegistrationPending').subscribe((isRegistrationPending) => {
          if (isRegistrationPending) {
            this.router.navigate(['/account/register']);
          } else if (this.router.url.includes('/account/login')) {
            const postLoginPath = sessionStorage.getItem(this._postLoginRedirectPath);
            if (postLoginPath) {
              sessionStorage.removeItem(this._postLoginRedirectPath);
              this.router.navigateByUrl(postLoginPath);
            } else {
              this.router.navigate(['/home/dashboard']);
            }
          }
        });
      });
    } else {
      this.logout();
    }
  }

  public async linkIdpAndForceLogin(identityProvider: string, redirectPath: string = '/home/dashboard') {
    await this.navigateToLinkingIdp(
      identityProvider,
      `/account/force-login-with-idp?idp=${identityProvider}&postLoginRedirectPath=${redirectPath}`,
    );
  }

  public async navigateToLinkingIdp(idp: string, redirectPath: string) {
    if (!this.oauthService.clientId || !this.oauthService.redirectUri) return;

    const nonce = crypto.randomUUID();

    const decodedToken = this.currentJwt.getValue();

    if (decodedToken == null) {
      return;
    }

    const hashInput = nonce + decodedToken.session_state + this.oauthService.clientId + idp;
    const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(hashInput));
    const hash = btoa(String.fromCharCode(...new Uint8Array(hashBuffer)));

    const searchParams = new URLSearchParams({
      client_id: this.oauthService.clientId,
      redirect_uri: this.oauthService.redirectUri + redirectPath,
      nonce,
      hash,
    });

    window.location.href = this.oauthService.issuer + `/broker/${idp}/link?` + searchParams;
  }
  logout() {
    // remove old localstorage entries from users that used the old login with .net identity
    localStorage.removeItem('loginToken');
    localStorage.removeItem('loginTokenTimestamp');

    // trigger logout flow only if we have a JWT currently, a loop may be triggered otherwise
    if (this.currentJwt.getValue()) {
      this.currentJwt.next(undefined);
      this.currentUserRolesSource.next(undefined);
      this.oauthService.revokeTokenAndLogout();

      if (this.router.url != '/account/login') {
        this.router.navigate(['/account/login']);
      }
    }
  }

  hasRole(roleToCheck: string): Observable<boolean> {
    return this.currentUserRoles$.pipe(
      map((roles) => {
        if (roles) {
          return roles.indexOf(roleToCheck) !== -1;
        }

        return false;
      }),
    );
  }

  hasAllRoles(rolesToCheck: string[]): Observable<boolean> {
    return this.currentUserRoles$.pipe(
      map((roles) => {
        if (roles) {
          return rolesToCheck.every((roleName) => roles.includes(roleName));
        }

        return false;
      }),
    );
  }

  hasAnyRole(rolesToCheck: string[]): Observable<boolean> {
    return this.currentUserRoles$.pipe(
      map((roles) => {
        if (roles) {
          return rolesToCheck.findIndex((roleName) => roles.includes(roleName)) > -1;
        }

        return false;
      }),
    );
  }

  loadUserLanguage() {
    /*
     After log in, load the language explicitly set on the profile.
     The case where the browser language is different from the profile language is probably rare,
     but we don't want to overwrite the profile language with our default choice of browser language if
     the user has never explicitly changed the language.
    */
    this.userService.getUserProfile().subscribe((result) => {
      if (result?.language) {
        this.setUserLanguage(result.language);
      }
    });
  }

  setUserLanguage(language: string) {
    this.translate.use(language);
    this.localeService.use(language);
    localStorage.setItem('userLanguage', language);
  }

  getUserLanguage(): string {
    return localStorage.getItem('userLanguage') || this.translate.getDefaultLang();
  }

  public loginWithIdp(idp: string) {
    this.oauthService.initImplicitFlow(undefined, {
      kc_idp_hint: idp,
    });
  }

  public async forceLoginWithIdp(idp: string, postLoginRedirectPath: string) {
    sessionStorage.setItem(this._postLoginRedirectPath, postLoginRedirectPath);
    this.oauthService.logOut(true); // do not redirect to logout, just clear session
    this.loginWithIdp(idp);
  }
}

interface DecodedToken {
  sub: string;
  preferred_username: string;
  identity_provider: string | undefined;
  session_state: string;
}

interface RegisterAccountResult {
  userId: string;
  lockoutEnabled: boolean;
}
